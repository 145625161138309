<div class="main-page">
  <app-banner [pageTitle]="pageTitleText" [isHome]="true"></app-banner>
  <section id="services" class="service section-bg">
    <div class="container aos-init aos-animate" data-aos="fade-up">
      <div class="section-title">
        <h2>Discover services we provided</h2>
        <p>India's Most Trusted Medical - UG &amp; PG Admission Counsellors; 100% Assured Admission For NEET UG 2022 Qualified Students</p>
      </div>
      <div class="row discover-block mg-20">
        <div class="col-lg-3">
          <div class="clg-block-2 card-border-2 clr-bg-1">
            <div class="clg-desc-2">
              <img src="assets/images/counselling-icon.png" class="img-fluid">
              <h4 class="clg-title">Counselling Eligibility</h4>
              <p>Check your eligibility for All India/State/ Private University Counselling</p>
              <!-- <div class="btn-list">
                <a class="my-buttion app" href="#" data-toggle="modal" data-target="#exampleModalCenter">Apply</a>
              </div> -->
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="clg-block-2 card-border-2 clr-bg-2">
            <div class="clg-desc-2">
              <img src="assets/images/transparent-process.png" class="img-fluid">
              <h4 class="clg-title">Transparent Process</h4>
              <p>No beating around the bush. Get straight and transparent analysis.</p>
              <!-- <div class="btn-list">
                <a class="my-buttion app" href="#" data-toggle="modal" data-target="#exampleModalCenter">Apply</a>
              </div> -->
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="clg-block-2 card-border-2 clr-bg-3">
            <div class="clg-desc-2">
              <img src="assets/images/college-predictor.png" class="img-fluid">
              <h4 class="clg-title">College Predictor </h4>
              <p>Get rank based college and course prediction just in one Click</p>
              <!-- <div class="btn-list">
                <a class="my-buttion app" href="#" data-toggle="modal" data-target="#exampleModalCenter">Apply</a>
              </div> -->
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="clg-block-2 card-border-2 clr-bg-4">
            <div class="clg-desc-2">
              <img src="assets/images/alerts-notification.png" class="img-fluid">
              <h4 class="clg-title">Alerts &amp; Notification</h4>
              <p>Date, Time &amp; Other NEET Updates &amp; Notification on SMS &amp; Email</p>
              <!-- <div class="btn-list">
                <a class="my-buttion app" href="#" data-toggle="modal" data-target="#exampleModalCenter">Apply</a>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- <section id="calltoaction1" class="section2">
    <div class="container">
      <div class="row">
          <div class="col-md-12">
            <div class="info-title">
              <h4>Enquiry Here</h4>
              <h2>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</h2>
            </div>
            <div class="btn-block">
              <a href="mailto:info@svbpi.co.in" class="btn-orange"><span>Email Us</span><span><i class="bi bi-telephone-fill"></i></span>info@svbpi.co.in</a>
            </div>
          </div>
      </div>
    </div>
  </section> -->
  <section id="confirm-admission" class="skills section-bg">
    <div class="container">
      <div class="section-title">
        <h2>Confirm Admission - Direct Admission Through Our Counselling</h2>
        <p>We understand the stress of a student, hence offer him/her with personalized guidance, support, and counselling, so that they realize their dream of studying at top medical colleges in India to fulfill their unambiguous career aspirations. </p>
      </div>
      <div class="row">
        <div class="col-md-5 col-sm-4 aos-init aos-animate" data-aos="zoom-out-right">
          <div class="help-content pd-l-40">
            <div class="text-block flex-right">
              <h5>Date and Time Update via Email and SMS</h5>
            </div>
            <div class="icon-block icon-block2 mg-l-10">
              <img src="assets/images/sms-updates.png" alt="Date and Time Update via Email and SMS">
            </div>
          </div>
          <div class="help-content pd-l-40">
            <div class="text-block flex-right">
              <h5>State Wise Counselling Assistance</h5>
            </div>
            <div class="icon-block icon-block2 mg-l-10">
              <img src="assets/images/state-wise-c.png" alt="State Wise Counselling Assistance">
            </div>
          </div>
          <div class="help-content pd-l-40">
            <div class="text-block flex-right">
              <h5>College, Course &amp; Fee Details</h5>
            </div>
            <div class="icon-block icon-block2 mg-l-10">
              <img src="assets/images/college.png" alt="College, Course &amp; Fee Details">
            </div>
          </div>
          <div class="help-content pd-l-40">
            <div class="text-block flex-right">
              <h5>College Mapping Based on Rank</h5>
            </div>
            <div class="icon-block icon-block2 mg-l-10">
              <img src="assets/images/college-mapping.png" alt="College Mapping Based on Rank">
            </div>
          </div>

        </div>
        <div class="col-md-2 col-sm-4 aos-init aos-animate" data-aos="fade-up">
          <div class="man-icon">
            <img src="assets/images/man_with_laptop.png">
          </div>
        </div>
        <div class="col-md-5 col-sm-4 aos-init aos-animate" data-aos="zoom-out-left">
          <div class="help-content mg-l-40">
            <div class="icon-block">
              <img src="assets/images/nri.png" alt="NRI Sponsored Quota Counselling Support">
            </div>
            <div class="text-block">
                <h5>NRI Sponsored Quota Counselling Support </h5>
            </div>
          </div>
          <div class="help-content mg-l-40">
            <div class="icon-block">
               <img src="assets/images/nri-documents.png" alt="NRI Documentation Support">
            </div>
            <div class="text-block">
                <h5>NRI Documentation Support</h5>
            </div>
          </div>
          <div class="help-content mg-l-40">
            <div class="icon-block">
              <img src="assets/images/assistance.png" alt="Assistance In Offline MOP-UP And Stray Round Counselling">
            </div>
            <div class="text-block">
                <h5>Assistance In Offline MOP-UP And Stray Round Counselling </h5>
            </div>
          </div>
          <div class="help-content mg-l-40">
            <div class="icon-block">
             <img src="assets/images/confirm-admission.png" alt="Confirm Admission Based on College Choice">
            </div>
            <div class="text-block">
                <h5>Confirm Admission Based on College Choice</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section id="contact" class="cta">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="section-title aos-init aos-animate" data-aos="zoom-in">
            <h2>Feel Free to Call</h2>
          </div>
        </div>
      </div>
      <div class="row">
          <div class="col-lg-4 text-center text-lg-start aos-init aos-animate" data-aos="slide-right">
            <div class="clg-pred-text">
              <div class="">
                <img src="assets/images/image-with-girl.png">
              </div>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="form-block">
              <div class="img-icon-with-form">
                <img src="assets/images/boy-with-laptop.png">
              </div>
              <h5>Fill Your Details</h5>
              <form class="php-email-form" action="mail.php" method="post">
              <div class="row">
                <div class="form-group col-md-6">
                  <label for="name">Your Name</label>
                  <input type="text" name="name" class="form-control" id="name" required="">
                </div>
                <div class="form-group col-md-6">
                  <label for="name">Your Email</label>
                  <input type="email" class="form-control" name="email" id="email" required="">
                </div>
              </div>
              <div class="row">
                <div class="form-group col-md-6">
                  <label for="name">Mobile Number</label>
                  <input type="text" class="form-control" name="phone" id="phone" required="">
                </div>
                <div class="form-group col-md-6">
                  <label for="name">Course Name</label>
                  <input type="text" class="form-control" name="neet_score" id="neet_score" required="">
                </div>
              </div>
              <div class="form-group">
                <label for="name">City</label>
                <input type="text" class="form-control" name="location" id="location" required="">
              </div>
              <div class="text-center"><button class="submit-btn" type="submit">Send Message</button></div>
            </form>
            </div>
          </div>
      </div>
    </div>
  </section>
  <section class="section2 bg-white">
    <div class="container">
      <div class="row">
          <div class="col-md-12">
            <div class="section-title" style="padding-bottom: 30px;">
              <h2>Join Our Newsletter</h2>
              <p>To get latest update, join our Newsletter!</p>
            </div>
            <div class="btn-block">
              <form action="" method="post">
                <input type="email" name="email" placeholder="Enter Your Email">
                <input type="submit" value="Subscribe">
              </form>
              <!-- <a href="tel:+919435186399" class="btn-orange"><span>Call Now</span><span><i class="bi bi-telephone-fill"></i></span>+91-9435186399</a> -->
            </div>
          </div>
      </div>
    </div>
  </section>
</div>
