<app-banner [pageTitle]="pageTitleText" [pageImageSrc]="imageSrc"></app-banner>

<!-- <section class="page-header" style="background: url('assets/img/background19.jpeg');">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h1>Contact Us</h1>
      </div>
    </div>
  </div>
</section> -->
<div class="contact-page">
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <div class="clg-address">
          <div class="info">
            <div class="address">
              <i class="bi bi-geo-alt"></i>
              <h4>Location:</h4>
              <p>Near Kalyan Udyog Mandir, Manek Chowk Rd, Old City, Danapidth, jenwadi, Ahmedabad, Gujarat 380001</p>
            </div>

            <div class="email">
              <i class="bi bi-envelope"></i>
              <h4>Email:</h4>
              <p><a href="mailto:info@svbpi.co.in">info@svbpi.co.in</a></p>
            </div>

            <!-- <div class="phone">
              <i class="bi bi-phone"></i>
              <h4>Call:</h4>
              <p>+91 000 000 0000</p>
            </div> -->
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="clg-enquiry-form php-email-form">
          <h1>Keep in Touch</h1>
          <form class="cf">
            <div class="half left cf">
              <input type="text" id="input-name" placeholder="Name">
              <input type="email" id="input-email" placeholder="Email address">
              <input type="text" id="input-subject" placeholder="Subject">
            </div>
            <div class="half right cf">
              <textarea name="message" type="text" id="input-message" placeholder="Message"></textarea>
            </div>
            <input type="submit" value="Submit" id="input-submit">
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="map-block">
  <iframe
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d29375.41925283313!2d72.5765754152327!3d23.0264374831816!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e85afac872183%3A0x7f5634dca8427658!2skalyan%20udyog%20mandir%20i%20ti!5e0!3m2!1sen!2sin!4v1647792817410!5m2!1sen!2sin"
    width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
</div>