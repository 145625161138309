<mat-stepper [linear]="isLinear" #stepper>
    <mat-step [stepControl]="personalDetails" label="Personal Details">
        <div class="form-block">
            <form [formGroup]="personalDetails">
                <div class="form-card">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="student-details" formGroupName="fullName">
                                <div class="row">
                                    <div class="col-md-6 col-xs-12">
                                        <mat-form-field appearance="legacy" class="reg-form-field">
                                            <mat-label>First Name</mat-label>
                                            <input matInput placeholder="First Name" formControlName="firstName">
                                        </mat-form-field>
                                        <!-- <label>First name</label>
                                        <input type="text" class="form-control" formControlName="firstName"> -->
                                    </div>
                                    <div class="col-md-6">
                                        <mat-form-field appearance="legacy" class="reg-form-field">
                                            <mat-label>Last Name</mat-label>
                                            <input matInput placeholder="Last Name" formControlName="lastName">
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col md-6">
                            <div class="parent-details" formGroupName="parentsDetails">
                                <div class="row">
                                    <div class="col-md-6">
                                        <mat-form-field appearance="legacy" class="reg-form-field">
                                            <mat-label>Father's name</mat-label>
                                            <input matInput placeholder="Father's Name" formControlName="fatherName">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-6">
                                        <mat-form-field appearance="legacy" class="reg-form-field">
                                            <mat-label>Mother's name</mat-label>
                                            <input matInput placeholder="Mother's name" formControlName="motherName">
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="student-dettails">
                        <div class="row">
                            <div class="col-md-3">
                                <mat-form-field color="accent" appearance="fill" class="reg-form-field">
                                    <mat-label>Enter DOB</mat-label>
                                    <input matInput [matDatepicker]="picker" formControlName="dob">
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div class="col-md-3">
                                <mat-form-field appearance="legacy" class="reg-form-field">
                                    <mat-label>Course Name</mat-label>
                                    <input matInput placeholder="Course Name" formControlName="courseName">
                                </mat-form-field>
                            </div>
                            <div class="col-md-3">
                                <mat-form-field appearance="legacy" class="reg-form-field">
                                    <mat-label>Center Code</mat-label>
                                    <input matInput placeholder="Center Code" formControlName="centerCode">
                                </mat-form-field>
                            </div>
                            <div class="col-md-3">
                                <mat-form-field appearance="legacy" class="reg-form-field">
                                    <mat-label>Session</mat-label>
                                    <input matInput placeholder="Session" formControlName="session">
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="student-dettails">
                        <div class="row">
                            <div class="col-md-3">
                                <mat-form-field appearance="legacy" class="reg-form-field">
                                    <mat-label>Phone Number</mat-label>
                                    <input matInput placeholder="Phone Number" formControlName="phoneNumber">
                                </mat-form-field>
                            </div>
                            <div class="col-md-3">
                                <mat-form-field appearance="legacy" class="reg-form-field">
                                    <mat-label>Email Id</mat-label>
                                    <input matInput placeholder="Email Id" formControlName="email">
                                </mat-form-field>
                            </div>
                            <div class="col-md-3">
                                <h5>Gender</h5>
                                <div class="custom-control custom-radio">
                                    <mat-radio-group aria-label="Select an option" formControlName="gender">
                                        <mat-radio-button value="male">Male</mat-radio-button>
                                        <mat-radio-button value="female">Female</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <mat-form-field appearance="fill">
                                    <mat-label>Select Course</mat-label>
                                    <mat-select [(value)]="selectedCourse" (selectionChange)="changeCourse($event.value)">
                                        <mat-option *ngFor="let course of course" [value]="course.value">
                                            {{course.viewValue}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <!-- <div *ngIf="selectedCourse">{{course.value}} - {{course.viewValue}}</div> -->
                            </div>
                        </div>
                    </div>
                    <div class="student-dettails">
                        <div class="row">
                            <div class="col-md-3">
                                <h5>Upload Image</h5>
                                <input type="file" (change)="uploadFile($event)" accept="{{allowFiles.join(',')}}">
                            </div>
                            <div class="col-md-6">
                                <!-- <div class="avtar-upload">
                                    <div class="avtar-edit">
                                        <input type='file' id="imageUpload" accept=".png, .jpg, .jpeg" #fileInput (change)="uploadFile($event)" />
                                        <label for="imageUpload" *ngIf="editFile" [ngClass]="['custom-label', 'upload-image']"></label>
                                        <label *ngIf="removeUpload" [ngClass]="['custom-label', 'remove-image']" (click)="removeUploadedFile()"></label>
                                    </div>
                                    <div class="avatar-preview">
                                        <div id="imagePreview" [style.backgroundImage]="'url('+ imageUrl +')'"></div>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="btn-block">

                    <!-- <button mat-button [ngClass]="disableEnableClass(personalDetails)" [attr.disable]="isPersoanlFormBtnDisable()" matStepperNext (click)="personalDetailsFormData()">Next</button> -->
                    <button mat-button  matStepperNext  (click)="personalDetailsFormData()">Next</button>
                </div>
            </form>
        </div>
        <!-- <ng-template matStepLabel>Personal Details</ng-template> -->
    </mat-step>
    <mat-step [stepControl] label="Subjects Details">
        <div class="form-block">
            <div class="add-subj">
                <a class="subj-add" (click)="addSubject()">
                    +<span translate>Add Subject</span>
                </a>
            </div>
            <div class="form-card">
                <div class="row">
                    <div class="col-md-12">
                        <p>Enrollment Number:- <span>{{enrollmentNumber}}</span></p>
                    </div>
                </div>
                <form [formGroup]="subjectForm">
                    <div class="row">
                        <div class="col-md-4">
                            <div formArrayName="subjectsName">
                                <div class="form-col" *ngFor="let city of subjectsName.controls; index as i">
                                    <!-- <label>Enter Subject Name</label> -->
                                    <input class="input-data" [formControlName]="i" placeholder="Enter Subject Name">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div formArrayName="subjectsCode">
                                <div class="form-col" *ngFor="let city of subjectsCode.controls; index as i">
                                    <!-- <label>Enter Subject Code</label> -->
                                    <input class="input-data" [formControlName]="i" placeholder="Enter Subject Code">
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="btn-block">
                <button mat-button matStepperPrevious>Back</button>
                <button mat-button matStepperNext [ngClass]="disableEnableClass(subjectForm)" (click)="subjectDetailsFormData()">Next</button>
            </div>
        </div>
    </mat-step>
    <mat-step [stepControl]="marksDetails" label="Marks Details">
        <div class="form-block">
            <form [formGroup]="marksDetails">
                <div class="form-card">
                    <div class="row">
                        <div class="col-md-12">
                            <p>Enrollment Number:- <span>{{enrollmentNumber}}</span></p>
                        </div>
                    </div>
                    <div class="row" formArrayName="marksList" *ngIf="marksList.controls.length>0">
                        <div class="marks-row" *ngFor="let city of marksList.controls; index as i">
                            <div class="form-row" [formGroupName]="i">
                                <div class="col-md-2 pd10">
                                    <div class="form-col">
                                        <!-- <label>Enter Subject Name</label> -->
                                        <input class="input-data" formControlName="marksSubjectName" placeholder="Enter Subject Name">
                                    </div>
                                </div>
                                <div class="col-md-2 pd10">
                                    <div class="form-col">
                                        <!-- <label>Enter Subject Code</label> -->
                                        <input class="input-data" formControlName="marksSubjectCode" placeholder="Enter Subject Name">
                                    </div>
                                </div>
                                <div class="col-md-2 pd10">
                                    <div class="form-col">
                                        <!-- <label>Max number</label> -->
                                        <input class="input-data" formControlName="maxNumber" placeholder="Max number" readonly>
                                    </div>
                                </div>
                                <div class="col-md-2 pd10">
                                    <div class="form-col">
                                        <!-- <label>Theory number</label> -->
                                        <!-- <input class="input-data" (blur)="updateTotalNum($event,'theoryNumber',i)" formControlName="theoryNumber" placeholder="Enter Theory Number"> -->
                                        <input type="number" class="input-data" (ngModelChange)="updateTotal(city)"  formControlName="theoryNumber" placeholder="Enter Theory Number">
                                    </div>
                                </div>
                                <div class="col-md-2 pd10">
                                    <div class="form-col">
                                        <!-- <label>Practicle number</label> -->
                                        <!-- <input class="input-data" (blur)="updateTotalNum($event,'practicleNumber',i)" formControlName="practicleNumber" placeholder="Enter Practicle Number"> -->
                                        <input type="number" class="input-data" (ngModelChange)="updateTotal(city)" formControlName="practicleNumber" placeholder="Enter Practicle Number">
                                    </div>
                                </div>
                                <div class="col-md-1 pd10">
                                    <div class="form-col">
                                        <!-- <label>Total number </label> -->
                                        <input class="input-data" formControlName="totalNumber" placeholder="Total Number" readonly>
                                    </div>
                                </div>
                                <div class="col-md-1 pd10">
                                    <div class="form-col">
                                        <!-- <label>Total number </label> -->
                                        <input class="input-data" formControlName="resultStatus" (ngModelChange)="resultStatus(city)" placeholder="Status" readonly>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="btn-block">
                    <button mat-button matStepperPrevious>Back</button>
                    <button mat-button matStepperNext (click)="marksDetailsFormData()">Next</button>
                </div>
            </form>
        </div>
    </mat-step>
    <mat-step>
        <ng-template matStepLabel>Done</ng-template>
        <p class="succes-msg">You have added Student Details Successfully for enrollment Number <span>{{enrollmentNumber}}</span></p>
        <div class="btn-block">
            <button mat-button matStepperPrevious>Back</button>
            <button mat-button (click)="stepper.reset();formReset()">Reset</button>
        </div>
    </mat-step>
</mat-stepper>




<!-- <div>
    <h1 class="form-heading">{{ stepItems[activeStepIndex].label }}</h1>
  
    <ng-container
      *ngFor="let form of masterForm; let i = index; trackBy: trackByFn"
    >
      <form
        action=""
        [formGroup]="masterForm[i]"
        *ngIf="i === activeStepIndex || masterForm.length === 1"
      >
        <div class="">
          <div class="fields-container">
            <div
              *ngFor="let field of formFields[i]; trackBy: trackByFn"
              [ngSwitch]="currentFormContent[i][field].type"
            >
              <div class="full-width">
                <label class="field-label text-bold" [for]="field">{{ field | formatTitle }}</label>
  
                <ng-container *ngSwitchCase="'textarea'">
                  <textarea
                    [id]="field"
                    [formControlName]="field"
                    [placeholder]="currentFormContent[i][field].placeholder"></textarea>
                </ng-container>
  
                <ng-container *ngSwitchCase="'file'">
                  <input type="file" (change)="onFileChange($event)" />
                </ng-container>
  
                <ng-container *ngSwitchCase="'select'">
                  <select [formControlName]="field" [id]="field">
                    <option value="">Select your Country</option>
                    <option
                      *ngFor="let option of currentFormContent[i][field].options; trackBy: trackByFn"
                      [value]="option.name"
                      >{{ option.name }}</option>
                  </select>
                </ng-container>
  
                <ng-container *ngSwitchDefault>
                  <input
                    [type]="currentFormContent[i][field].type"
                    [formControlName]="field"
                    [id]="field"
                    [placeholder]="currentFormContent[i][field].placeholder"
                  />
                </ng-container>
              </div>
  
              <p
                class="validation-error"
                *ngIf="
                  (masterForm[i].get(field).dirty ||
                    masterForm[i].get(field).touched) &&
                  masterForm[i].get(field).invalid &&
                  masterForm[i].get(field).errors
                "
              >
                {{ getValidationMessage(i, field) }}
              </p>
            </div>
          </div>
  
          <div
            class="preview-container"
            *ngIf="activeStepIndex === stepItems.length - 1">
            <ul>
              <li
                class="form-details-list"
                *ngFor="let field of masterFormFields; trackBy: trackByFn">
                <span class="text-bold">{{ field | formatTitle }}</span>
                <span>{{ formData[field] || 'N/A' }}</span>
              </li>
            </ul>
          </div>
  
          <div class="button-container">
            <button
              type="button"
              [disabled]="!activeStepIndex"
              (click)="goToStep('prev')"
            >Previous</button>
            <button
              *ngIf="activeStepIndex === stepItems.length - 1"
              type="button"
              (click)="onFormSubmit()"
            >Submit</button>
            <button
              type="button"
              [disabled]="activeStepIndex === stepItems.length - 1 || masterForm[i].invalid"
              (click)="goToStep('next')"
            >Next</button>
          </div>
        </div>
      </form>
    </ng-container>
  </div>
   -->