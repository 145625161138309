<footer class="bg-dark text-light">
    <div class="svg-footer">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
          <path class="elementor-shape-fill" d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
          c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
          c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"></path>
        </svg>
    </div>
    <div class="container">
        <div class="f-items default-padding">
            <div class="row">
                <div class="col-lg-3 col-md-3">
                    <div class="f-item about">
                        <!-- <img src="assets/images/mbbs-counselling-ftr-logo.svg" alt="Logo"> -->
                        <img src="assets/images/logonew.jpeg" alt="Logo">
                        <ul>
                            <li><i class="bi bi-house-door-fill"></i>
                              <span>Near Kalyan Udyog Mandir, Manek Chowk Rd, Old City, Danapidth, jenwadi, Ahmedabad, Gujarat 380001</span>
                            </li>
                            <!-- <li>
                              <i class="bi bi-telephone-fill"></i>
                              <a href="tel:+919435186399">+91-9435186399</a>
                            </li> -->
                            <li><i class="bi bi-envelope-fill"></i><a href="mailto:info@svbpi.co.in">info@svbpi.co.in</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-xs-6 item">
                    <div class="f-item link">
                        <h4 class="widget-title">Usefull Links</h4>
                        <ul>
                            <li>
                                <a href="/home">Home</a>
                            </li>
                            <li>
                                <a href="/about">About US</a>
                            </li>
                            <li>
                                <a href="/result">Result</a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-xs-6 item">
                    <div class="f-item link">
                        <h4 class="widget-title">Usefull Links</h4>
                        <ul>
                            <li>
                                <a href="/courses">Course</a>
                            </li>
                            <li>
                                <a href="/admitcard">Admit Card</a>
                            </li>
                            <li>
                                <a href="/contact">Contact</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- <div class="col-lg-3 col-md-6 item">
                    <div class="f-item link">
                        <h4 class="widget-title">Social Networks</h4>
                        <ul>
                            <li>
                                <a href="/contact">Contact</a>
                            </li>
                            <li>
                                <a href="#">Facebook</a>
                            </li>
                            <li>
                                <a href="#">Instagram</a>
                            </li>
                        </ul>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
    <!-- Footer Bottom -->
    <div class="footer-bottom">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <p>© Copyright 2022. All Rights Reserved by SVBPI</p>
                </div>
                <div class="col-lg-6 text-right link">
                    <ul>
                        <li>
                            Designed and Developed by <a href="/home">SVBPI</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <!-- End Footer Bottom -->
</footer>