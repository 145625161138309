<mat-nav-list>
  <a mat-list-item href="/home" (click)="onSideNavClose()">
    <span class="nav-caption">Home</span>
  </a>
  <a mat-list-item href="/about" (click)="onSideNavClose()">
    <span class="nav-caption">About Us</span>
  </a>
  <a mat-list-item href="/result" (click)="onSideNavClose()">
    <span class="nav-caption">Result</span>
  </a>
  <a mat-list-item href="/courses" (click)="onSideNavClose()">
    <span class="nav-caption">Courses</span>
  </a>
  <a mat-list-item href="/admitcard" (click)="onSideNavClose()">
    <span class="nav-caption">Admit Card</span>
  </a>
  <a mat-list-item href="/contact" (click)="onSideNavClose()">
    <span class="nav-caption">Contact Us</span>
  </a>
</mat-nav-list>
