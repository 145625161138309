<app-banner [pageTitle]="pageTitleText" [pageImageSrc]="imageSrc"></app-banner>

<!-- <section class="page-header" style="background: url('assets/img/siteName.png');">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
      </div>
    </div>
  </div>
</section> -->
<section class="course-details-block">
  <div class="container">
    <div class="row">
      <div class="col-md-12" style="margin-bottom:20px">
        <div class="section-title">
          <h2>Our Diploma Courses</h2>
        </div>
        <div class="course">
          <ul>
            <li>Diploma</li>
            <li>Diploma in Ophthalmic Technology</li>
            <li>Diploma in Dental hygenist</li>
            <li>Diploma in Medical Record Technology</li>
            <li>Diploma in X-Ray Technology</li>
            <li>Diploma in DialysisTechnology</li>
            <li>Diploma in Medical imaging Technology</li>
            <li>Diploma in OT Technician</li>
            <li>Dip in Fitter</li>
            <li>Dip.In Computer Hardware& Networking</li>
            <li>JOURNALISM & MASS COMMUNICATION</li>
            <li>Management</li>
            <li>Hotel Management</li>
            <li>Cooking</li>
            <li>Dip In Electrician Engg</li>
            <li>Motor Mechanic Engg.</li>
            <li>ITI</li>
            <li>Diesel Mechanic</li>
            <li>General duty Assistant (GDA)</li>
            <li>Diploma in Rural Health Care</li>
            <li>ANM</li>
            <li>CMLT</li>
            <li>Diploma in pharmacy</li>
        <li>Diploma in Medical Lab Technician</li>
        <li>PG DIPLOMA IN PHYSICIAN ASSISTANT</li>
        <li>MEDICAL DRESSER</li>
        <li>NURSING ASST</li>
        <li>DENTAL ASST</li>
        <li>GNM</li>
        <li>BEMS</li>
        <li>CMLT</li>
        <li>GDA</li>
        <li>Dip in Fitter</li>
        <li>Dip In Operation Theatre Assistant</li>
        <li>Dip In Tool &amp; Die</li>
        <li>Electronics &amp; Communication</li>
        <li>Dip In course Of Concept On Computer</li>
        <li>ITI ELECTRICIAN</li>
        <li>Dip in X-Ray Technology</li>
        <li>Dip In Live Stock</li>
        <li>Electrical &amp; Electronics</li>
        <li>Dip In Beautician (6 Months)</li>
        <li>Dip In Computer Operator &amp; Programming Asst.</li>
        <li>Dip In Computer Teacher Training</li>
        <li>Dip In Librarian</li>
        <li>Dip In Store Management</li>
        <li>Dip In Sanitary Inspection</li>
        <li>Dip in Business Management</li>
        <li>Computer Software Six Months</li>
        <li>Dip in Mech.-Cum-Operator Elec.Comm.</li>
        <li>Dip in Embroidery &amp; Needle Work</li>
        <li>Dip in Radiation Imaging &amp; ECG Tech</li>
        <li>Diary Technology</li>
        <li>Dip In Food &amp; Nutrition</li>
        <li>Dip In Computer Application</li>
        <li>Dip In Printing</li>
        <li>Textile</li>
        <li>Dip In Retail Management</li>
        <li>Dip In Fireman</li>
        <li>Dip In Project Management</li>
        <li>Dip In Operation Theatre</li>
        <li>DIP IN MATERIAL MANAGEMENT</li>
        <li>DIP IN COOK</li>
        <li>DIP IN INFORMATION TECHNOLOGY</li>
        <li>Leather Manufacturing</li>
        <li>AC &amp; AIR CONDITION</li>
        <li>SUPPLY CHAIN MANAGEMENT</li>
        <li>DIP IN SUPPLY CHAIN MANAGEMENT</li>
        <li>Instrument &amp; Technician</li>
        <li>Dip.In Financial Accounting</li>
        <li>Litho Offset Machine Minder</li>
        <li>Painter</li>
        <li>Welder &amp; Federation</li>
        <li>Dip In Instrumentation Technology</li>
        <li>Welder &amp; Fabrication</li>
        <li>Pump Operator</li>
        <li>Tele Communication</li>
        <li>Dip.In Bakery Confectionary</li>
        <li>Dip.In Excel Face To Face Online Program</li>
        <li>Dip in Computer Science Engg.</li>
        <li>Dip In Tool &amp; Die</li>
        <li>Dip in Automobile Engg.</li>
        <li>Dip In Computer Science &amp; Engg.</li>
        <li>Dip in Architecture</li>
        <li>Dip In Civil Building Material</li>
        <li>Dip In Hotel Management</li>
          </ul>
        </div>
      </div>
      <div class="col-md-12">
        <div class="section-title">
          <h2>Our Graduaction Courses</h2>
        </div>
        <div class="course">
          <ul>
            <li>B-Tech</li>
            <li>BBA</li>
            <li>Bsc (Medical lab Technology)</li>
            <li>Bds</li>
            <li>B-Pharma</li>
            <li>BAMS</li>
            <li>BMLT</li>
            <li>CMSED</li>
            <li>MBA</li>
            <li>Bachelor of homeopathic Medicine and surgery</li>
            <li>Bachelor of physiotherapy</li>
            <li>Bsc in operation theatre technology</li>
            <li>Master Of Business Administration</li>
            <li>MBA(Management)</li>
            <li>MBA(IT)</li>
            <li>Master of Business Administration(HR)</li>
            <li>Master of Computer Application</li>
            <li>Industrial in Electrical Repair &amp; Maintenance</li>
            <li>MBA IN INFORMATION TECHNOLOGY(EXECUTIVE)</li>
            <li>PG Dip. in Rural Development</li>
            <li>Bachelor of Business Management</li>
            <li>Bachelor-Programming Tech.(Mech)</li>
            <li>Bachelor-Programming Tech.(Electronics &amp; Telecommunication Engg.)</li>
            <li>Bechelor Programming Technology (Electrical &amp; Communication)</li>
            <li>Bechelor Programming Technology (Electronics &amp; Communication)</li>
            <li>Bachelor In Computer Science</li>
            <li>Bachelor Programming in Technology ( Computer Science)</li>
            <li>Bachelor Programing In(Computer Science)</li>
            <li>Computer Science</li>
            <li>Bachelor-programming in tech.(Mech).</li>
            <li>Bachelor-Programming IN technology(IT)</li>
            <li>Bachelor Programming in Tech.(Electrical &amp; Electronics)</li>
            <li>Bachelor Programming in Tech.(Automobile Engg)</li>
            <li>Bachelor Program in Technology(Civil)</li>
            <li>Bachelor-Program In Technology(CHEM.ENGG.)</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- <div class="container-list">
  <div class="row">
    <div class="col-md-6">
      <h2>
        Course Name
      </h2>
      <ul>
        <li>Diploma</li>
        <li>Engineering</li>
        <li>Dip in Fitter</li>
        <li>Management</li>
        <li>B-Tech</li>
        <li>Semester</li>
        <li>Semester(MECH)</li>
        <li>Dip.In Computer Hardware& Networking</li>
        <li>JOURNALISM & MASS COMMUNICATION</li>
        <li>Management</li>
        <li>Hotel Management</li>
        <li>M-Tech</li>
        <li>Bachelor</li>
        <li>Cooking</li>
        <li>BBA</li>
        <li>Dip In Electrician Engg</li>
        <li>Motor Mechanic Engg.</li>
        <li>B-TECH</li>
        <li>Certificate</li>
        <li>ITI</li>
        <li>MBA</li>
        <li>BBA</li>
        <li>MCA</li>
        <li>Certificate</li>
        <li>Diesel Mechanic</li>
        <li>MBA</li>
        <li>PG</li>
        <li>DNM</li>
        <li>Diploma in pharmacy</li>
        <li>Diploma in Medical Lab Technician</li>
        <li>GNM</li>
        <li>BEMS</li>
        <li>CMLT</li>
        <li>NURSING ASST</li>
        <li>GDA</li>
        <li>DENTAL ASST</li>
        <li>MEDICAL DRESSER</li>
        <li>PG DIPLOMA IN PHYSICIAN ASSISTANT</li>
        <li>Bds</li>
        <li>B-Pharma</li>
        <li>BAMS</li>
        <li>BMLT</li>
        <li>CMSED</li>
        <li>Diploma in physiotherapy</li>
        <li>Bachelor in medical imaging technology</li>
        <li>Diploma in opthalmic technical assistant</li>
        <li>Multi purpose health worker</li>
        <li>ECG Technician</li>
        <li>Bachelor of homeopathic Medicine and surgery</li>
        <li>Bachelor of physiotherapy</li>
        <li>Bsc in operation theatre technology</li>
        <li>Bsc in dialysis Therapy</li>
        <li>Bsc nursing</li>
        <li>Bsc (Medical lab Technology)</li>
        <li>Diploma in Ophthalmic Technology</li>
        <li>Diploma in Dental hygenist</li>
        <li>Diploma in Medical Record Technology</li>
        <li>Diploma in X-Ray Technology</li>
        <li>Diploma in DialysisTechnology</li>
        <li>Diploma in Medical imaging Technology</li>
        <li>Diploma in OT Technician</li>
        <li>General duty Assistant (GDA)</li>
        <li>Diploma in Rural Health Care</li>
        <li>ANM</li>
        <li>CMLT</li>
      </ul>
    </div>
    <div class="col-md-6">
      <h2>
        Branch/Trade Name
      </h2>
      <ul>
        <li>Electrician</li>
        <li>Electrical</li>
        <li>Mechanical</li>
        <li>Fitter</li>
        <li>Drafts Man (Mech)</li>
        <li>Machinist</li>
        <li>Welder</li>
        <li>Plumber</li>
        <li>Art &amp; Craft</li>
        <li>Computer software</li>
        <li>Computer Hardware</li>
        <li>Hardware Networking</li>
        <li>N.T.T</li>
        <li>P.T.T</li>
        <li>N.P.T.T</li>
        <li>Carpenter</li>
        <li>Beauty Culture</li>
        <li>Auto Mobile</li>
        <li>Dip in Electronics</li>
        <li>AC &amp; Refrigeration</li>
        <li>Dressmaking Tailoring</li>
        <li>Cutting &amp; Tailoring</li>
        <li>PG. Dip in Computer Application</li>
        <li>Draughtsman(Civil)</li>
        <li>Draughtsman(Mech)</li>
        <li>Diesel Mechanic</li>
        <li>Dip In Line Men</li>
        <li>Dip In Fire Safety Management</li>
        <li>Computer software 6 months</li>
        <li>Tally 3 months</li>
        <li>Multipurpose Health Worker</li>
        <li>Motor Mechanic</li>
        <li>Tractor Mechanical</li>
        <li>Gen. Painter</li>
        <li>Draftsman (Civil)</li>
        <li>Tally 3 month</li>
        <li>Dip In Medical Lab. Tech.</li>
        <li>Bioler</li>
        <li>BOILER</li>
        <li>Dip in Socia Work</li>
        <li>AC &amp; Air Condition</li>
        <li>Computer Application</li>
        <li>Dip in Civil Surveyor</li>
        <li>Turner</li>
        <li>Computer Science</li>
        <li>Dip in Hairstyles &amp; skincare</li>
        <li>DIP IN CIVIL</li>
        <li>Draftsman</li>
        <li>Dip n Civil</li>
        <li>WIREMAN</li>
        <li>Computer Hardware &amp; Networking</li>
        <li>FINANCIAL ACCOUNTING</li>
        <li>PG Dip In Mass Communication</li>
        <li>Dip In Information Tech.</li>
        <li>Welding &amp; Fitting Mechanic(Dual mode)</li>
        <li>Hotel Management</li>
        <li>Dip in Fitter</li>
        <li>Dip In Operation Theatre Assistant</li>
        <li>Dip In Tool &amp; Die</li>
        <li>Electronics &amp; Communication</li>
        <li>Dip In course Of Concept On Computer</li>
        <li>ITI ELECTRICIAN</li>
        <li>Dip in X-Ray Technology</li>
        <li>Dip In Live Stock</li>
        <li>Electrical &amp; Electronics</li>
        <li>Dip In Beautician (6 Months)</li>
        <li>Dip In Computer Operator &amp; Programming Asst.</li>
        <li>Dip In Computer Teacher Training</li>
        <li>Dip In Librarian</li>
        <li>Dip In Store Management</li>
        <li>Dip In Sanitary Inspection</li>
        <li>Dip in Business Management</li>
        <li>Computer Software Six Months</li>
        <li>Dip in Mech.-Cum-Operator Elec.Comm.</li>
        <li>Dip in Embroidery &amp; Needle Work</li>
        <li>Dip in Radiation Imaging &amp; ECG Tech</li>
        <li>Diary Technology</li>
        <li>Dip In Food &amp; Nutrition</li>
        <li>Dip In Computer Application</li>
        <li>Dip In Printing</li>
        <li>Textile</li>
        <li>Dip In Retail Management</li>
        <li>Dip In Fireman</li>
        <li>Dip In Project Management</li>
        <li>Dip In Operation Theatre</li>
        <li>DIP IN MATERIAL MANAGEMENT</li>
        <li>DIP IN COOK</li>
        <li>DIP IN INFORMATION TECHNOLOGY</li>
        <li>Leather Manufacturing</li>
        <li>AC &amp; AIR CONDITION</li>
        <li>SUPPLY CHAIN MANAGEMENT</li>
        <li>DIP IN SUPPLY CHAIN MANAGEMENT</li>
        <li>Instrument &amp; Technician</li>
        <li>Dip.In Financial Accounting</li>
        <li>Litho Offset Machine Minder</li>
        <li>Painter</li>
        <li>Welder &amp; Federation</li>
        <li>Dip In Instrumentation Technology</li>
        <li>Welder &amp; Fabrication</li>
        <li>Pump Operator</li>
        <li>Tele Communication</li>
        <li>Dip.In Bakery Confectionary</li>
        <li>Dip.In Excel Face To Face Online Program</li>
        <li>Bachelor In Librarian</li>
        <li>Cooking/Cookery</li>
        <li>Lab Technician</li>
        <li>Diploma In Fashion Designing</li>
        <li>welder mechanic</li>
        <li>cooking</li>
        <li>Health Visitor</li>
        <li>Health Sanitary Inspector</li>
        <li>Dip.In Computer Hardware &amp; Networking</li>
        <li>Boiler Operator</li>
        <li>Kathak Dance</li>
        <li>Dip. In Typing</li>
        <li>Dip.In Short Hand</li>
        <li>Dip. In Chemical</li>
        <li>Agriculture</li>
        <li>Dip. In Cookery</li>
        <li>Cookery</li>
        <li>letter Press Machine Minder</li>
        <li>Draughtsman(Mech.)</li>
        <li>P.G Dip.In Information Technology</li>
        <li>Industrial Safety</li>
        <li>Script Writing</li>
        <li>Auxiliary Nurses &amp; Midwifery</li>
        <li>Hair &amp; Skin Care</li>
        <li>Beauty &amp; Health Care</li>
        <li>Civil Structure</li>
        <li>PG Dip.In Hospital &amp; Health Management</li>
        <li>Lineman</li>
        <li>Fright Forwarding</li>
        <li>Medical Assistance</li>
        <li>Commercial Cookery</li>
        <li>Medical Assistant</li>
        <li>Sanitary Inspector</li>
        <li>Dip.in Yoga</li>
        <li>Hair Dressing</li>
        <li>PG Dip. in Rural Development</li>
        <li>Dip.in Food Production</li>
        <li>Mechanical Production</li>
        <li>PG.Dip.in Hospital &amp; Health Mgmt.</li>
        <li>PG.Dip.in Personal Manangement</li>
        <li>Diploma In Computer Hardware &amp; Software</li>
        <li>Mechanical &amp; Instrument</li>
        <li>P.G.Diploma in Office Management</li>
        <li>P.G. Diploma in Hotel Management</li>
        <li>Auto Mechanical</li>
        <li>Community Medical Service</li>
        <li>Cable Wireman</li>
        <li>Computer Software &amp; Hardware Networking</li>
        <li>Computer Hardware Networking</li>
        <li>Typing</li>
        <li>Shorthand</li>
        <li>Industrial Safety &amp; Hazard Mgmt</li>
        <li>Hindi Typing</li>
        <li>English Typing</li>
        <li>Tally &amp; Accounting</li>
        <li>Building Construction</li>
        <li>Diploma in Horticulture</li>
        <li>Beauty &amp; Wellness</li>
        <li>Diploma in Co-operative Mgmt.</li>
        <li>PG Diploma in Social Worker</li>
        <li>Dip In Electrical</li>
        <li>Dip In Electronics &amp; Comm.</li>
        <li>Dip in Mechanical Engg</li>
        <li>Dip in Civil Engg.</li>
        <li>Dip In Electrical Engg.</li>
        <li>Electronics &amp; Telecomm. Engg</li>
        <li>Dip in Computer Science Engg.</li>
        <li>Dip In Tool &amp; Die</li>
        <li>Dip in Automobile Engg.</li>
        <li>Dip In Computer Science &amp; Engg.</li>
        <li>Dip in Architecture</li>
        <li>Dip In Civil Building Material</li>
        <li>Dip In Hotel Management</li>
        <li>Bachelor-Program in Technology(Civil)</li>
        <li>Degree In Dairy Technology</li>
        <li>Dip In BBA</li>
        <li>DIPLOMA IN INSTRUMENTATION ENGG</li>
        <li>Bachelor-Program in Business Administration</li>
        <li>Dip in Civil (Contraction Line)</li>
        <li>Dip in Draftsman (Civil)</li>
        <li>Dip In Business Management</li>
        <li>Electrical Engg.</li>
        <li>Dip In Hospitality Engg.</li>
        <li>Dip In Chemical Engg.</li>
        <li>DIP IN INFORMATION TECHNOLOGY</li>
        <li>Dip.In Computer Hardware&amp;Networking</li>
        <li>Bachelor Of Medical Lab Technology</li>
        <li>Electrical &amp; Electronics Engg.</li>
        <li>Bechelor Program In Journalism</li>
        <li>Welding Technology</li>
        <li>Dip In Boiler Engg</li>
        <li>Dip In Hotel Management Inn Food &amp; Beverages</li>
        <li>Dip.In Mining Engg</li>
        <li>Dip.In Fire &amp; Safety</li>
        <li>Dip.In Electrician Engg</li>
        <li>Motor Mechanic Engg.</li>
        <li>Dip.In Welder &amp; Fabrication</li>
        <li>Dip.in Dairy &amp; Food Production</li>
        <li>Dip.In Electronics Engg.</li>
        <li>Dip. in Mechanical Engg.(Tool &amp; Die)</li>
        <li>Dip. in Accounting</li>
        <li>Dip.in Civil Surveyor</li>
        <li>Diploma in Cooking</li>
        <li>Dip in Electrical Engg.</li>
        <li>Dip in Mechanical Engg.</li>
        <li>Dip.In Textile Engg.</li>
        <li>B.Tech</li>
        <li>Bachelor-Program in Tech.(Civil)….</li>
        <li>Bachelor-Program in Technology(Elec)</li>
        <li>BACHELOR-PROGRAM IN TECHNOLOGY(MECH).</li>
        <li>Master Of Business Executive(HR)</li>
        <li>Dip In Business Management</li>
        <li>Dip in Hotel Management in food. &amp; Beverages</li>
        <li>Bachelor of Business Management</li>
        <li>Bachelor-Programming Tech.(Mech)</li>
        <li>Bachelor-Programming Tech.(Electronics &amp; Telecommunication Engg.)</li>
        <li>Bechelor Programming Technology (Electrical &amp; Communication)</li>
        <li>Bechelor Programming Technology (Electronics &amp; Communication)</li>
        <li>Bachelor In Computer Science</li>
        <li>Bachelor Programming in Technology ( Computer Science)</li>
        <li>Bachelor Programing In(Computer Science)</li>
        <li>Computer Science</li>
        <li>Bachelor-programming in tech.(Mech).</li>
        <li>Bachelor-Programming IN technology(IT)</li>
        <li>Bachelor Programming in Tech.(Electrical &amp; Electronics)</li>
        <li>Bachelor Programming in Tech.(Automobile Engg)</li>
        <li>Bachelor Program in Technology(Civil)</li>
        <li>Bachelor-Program In Technology(CHEM.ENGG.)</li>
        <li>Dip in Mechanical Engg.</li>
        <li>MECHANICAL ENGG</li>
        <li>BACHELOR PROGRAM IN JOURNALISM</li>
        <li>Gurgaon</li>
        <li>Dip in Hotel Management in Food &amp; Beverages</li>
        <li>Master Of Technology(Civil Engg)</li>
        <li>Master Of Technology(Civil Engg.)</li>
        <li>Bachelor In Librarian</li>
        <li>Bachelor Of Computer Application</li>
        <li>Bachelor of Business Administration</li>
        <li>B.E.Mechanical Engg.</li>
        <li>B.Sc in Hotel Management</li>
        <li>Bachelor Of Business Administration</li>
        <li>Certificate In Welder</li>
        <li>Certificate In Medical Lab Tech</li>
        <li>Welder</li>
        <li>ELECTRICIAN</li>
        <li>FITTER</li>
        <li>Electrical</li>
        <li>Diesel Mechanic</li>
        <li>Electronic</li>
        <li>Civil</li>
        <li>Master Of Business Administration</li>
        <li>MBA(Management)</li>
        <li>MBA(IT)</li>
        <li>Master of Business Administration(HR)</li>
        <li>Master of Computer Application</li>
        <li>Industrial in Electrical Repair &amp; Maintenance</li>
        <li>MBA IN INFORMATION TECHNOLOGY(EXECUTIVE)</li>
      </ul>
    </div>
  </div>
</div> -->