import { Component, OnInit, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Output() public sideNavToggle = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }
  /**
   *
   */
  public onToggleSidenav(){
    this.sideNavToggle.emit();
  }
}
