<mat-toolbar color="custom" class="custom-toolbar">  
  <div class="top-hader">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="navigation-block">
            <div class="logo">
              <a routerLink="/home">
                <img src="assets/images/logonew.jpeg" class="img-responsive" alt="SVBPI">
              </a>
            </div>
            <div class="navigation">
              <ul class="navigation-items">
                <li><a class="nav-link" href="/home">Home</a></li>
                <li><a class="nav-link" href="/about">About</a></li>
                <li><a class="nav-link" href="/result">Result</a></li>
                <li><a class="nav-link" href="/courses">Courses</a></li>
                <li><a class="nav-link" href="/admitcard">Admit Card</a></li>
                <li><a class="nav-link" href="/contact">Contact</a></li>
              </ul>
            </div>
          </div>
          <button mat-icon-button (click)="onToggleSidenav()" class="menu-button">
            <mat-icon>menu</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</mat-toolbar>
