<app-banner [pageTitle]="pageTitleText" [pageImageSrc]="imageSrc"></app-banner>

<!-- <section class="page-header" style="background: url('assets/img/header/sense_banner.jpeg');">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
      </div>
    </div>
  </div>
</section> -->

<section class="admit-block">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <form role="search" [formGroup]="enrollSearch" (ngSubmit)="onSubmit()" id="enroll">
          <div class="wrapper">
            <div class="search_box">
              <input class="input_search" id="input_search" type="text" placeholder="Search Enrolment no. here .."
                autocomplete="off" autofocus title="search" formControlName="enrollNumValue">
              <div class="search_btn">
                <button class="btn btn-black search-btn" type="submit">
                  <mat-icon aria-hidden="false" aria-label="Example search icon">search</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
<section class="download-admit" *ngIf="data && data.userData">
  <div class="container">
    <div class="row">
      <div class="col-md-12 admit-final">
        <div id="admit-card-details" class="admit-card-details">
          <div class="admit-card-header">
            <img src="assets/img/admitcardlogo.png">
            <h2>
              <span>ACKNOWLEDGEMENT SLIP</span>
            </h2>
          </div>
          <div class="responsive-table">
            <table class="border-table">
              <tr>
                <th>Enrollment Number</th>
                <th>Institute/Center Code</th>
                <th>Session</th>
              </tr>
              <tr>
                <td>{{data.userData.enrollmentNo}}</td>
                <td>{{data.userData.centerCode}}</td>
                <td>{{data.userData.session}}</td>
              </tr>
            </table>
          </div>
          <div class="row flex-container">
            <div class="col-md-9 flex-child">
              <div class="responsive-table">
                <table class="st-personal-details">
                  <tr>
                    <th>Name Of Student:</th>
                    <td>{{data.userData.firstName}}&nbsp;{{data.userData.lastName}}</td>
                  </tr>
                  <tr>
                    <th>Father's Name:</th>
                    <td>{{data.userData.fatherName}}</td>
                  </tr>
                  <tr>
                    <th>Mother's Name:</th>
                    <td>{{data.userData.motherName}}</td>
                  </tr>
                  <tr>
                    <th>DOB:</th>
                    <td>{{data.userData.dob}}</td>
                  </tr>
                  <tr>
                    <th>Course Name:</th>
                    <td>{{data.userData.courseName}}</td>
                  </tr>
                </table>
              </div>
            </div>
            <div class="col-md-3 flex-child">
              <div class="st-profile-pic"> <img class="user" src="{{data.userData.profileImage}}" alt="profile image"/></div>
            </div>
          </div>
          <div class="responsive-table">
            <table class="st-subjects">
              <tr>
                <th style="width: 22%;">S.No</th>
                <th>Subjects</th>
                <th>Subjects Code</th>
              </tr>
              <tr *ngFor="let subject of data.subjectDeatils;let i = index">
                <td>{{i+1}}</td>
                <td>{{subject.subject}}</td>
                <td>{{subject.subjectCode}}</td>
              </tr>
              <tr>
                <th>Name of Institute/Center</th>
                <td colspan="2" style="border-top:1px solid #000"
                  *ngIf="data&&data.userData&&data.userData.enrollmentNo&&data.userData.enrollmentNo.includes('PMS')">
                  Sardar VallabhBhai Patel Institute Of Paramedical & Sciences
                </td>
                <td colspan="2" style="border-top:1px solid #000"
                  *ngIf="data&&data.userData&&data.userData.enrollmentNo&&!(data.userData.enrollmentNo.includes('PMS'))">
                  Sardar VallabhBhai Patel Institute of Technology & Management
                </td>
              </tr>
            </table>
          </div>
          <div class="note">
            <p><b>Note:-</b> Your application has been accepted. Please download your enrollment slip by SVBPI official
              website (http://svbpi.co.in/) within 15 days</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="form-btn-block">
    <button class="download-admit btn btn-danger btn-lg btn-block" id="download-pdf"
      (click)="clickFunction('admit-card-details',data.userData.enrollmentNo)">Download</button>
  </div>

  <script>
    const downloadPdf = document
      .getElementById("#download-pdf");

    const submitForm = document
      .querySelector("#submit-form");

    downloadPdf.addEventListener("click", () => {
    });

  </script>
</section>
<section class="no-result-found" *ngIf="!data">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <p>No Result Found</p>
      </div>
    </div>
  </div>
</section>
<!-- Result section -->
<!-- <section class="download-admit">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="admit-card-details">
          <div class="admit-card-header">
            <img src="assets/img/siteName.png">
            <h2>
              <span>Acknowledgement Slip</span></h2>
          </div>
          <div class="responsive-table">
            <table class="border-table">
              <tr>
                <th>Enrollment Number</th>
                <th>Institute/Center Code</th>
                <th>Session</th>
              </tr>
              <tr>
                <td>SVPI/10/1000</td>
                <td>Center Code</td>
                <td>2020-22</td>
              </tr>
            </table>
          </div>
          <div class="col-md-9">
            <div class="responsive-table">
              <table class="st-personal-details">
                <tr>
                  <th>Name Of Student:</th><td>SHYAM LAL</td>
                </tr>
                <tr>
                  <th>Father's Name:</th><td>KIRPA RAM</td>
                </tr>
                <tr>
                  <th>Mother's Name:</th><td>ROSHANI DEVI</td>
                </tr>
                <tr>
                  <th>DOB:</th><td>12-09-1985</td>
                </tr>
                <tr>
                  <th>Course Name:</th><td>DIPLOMA IN MINING ENGINEERING</td>
                </tr>
              </table>
            </div>
          </div>
          <div class="col-md-3">
            <div class="st-profile-pic"></div>
          </div>
          <div class="responsive-table">
            <table class="st-subjects">
              <tr>
                <th>S.No</th>
                <th>Subjects</th>
                <th>Subjects Code</th>
              </tr>
              <tr>
                <td>1</td>
                <td>Basic Mining Engineering</td>
                <td>105</td>
              </tr>
              <tr>
                <td>1</td>
                <td>Basic Mining Engineering</td>
                <td>105</td>
              </tr>
              <tr>
                <td>1</td>
                <td>Basic Mining Engineering</td>
                <td>105</td>
              </tr>
              <tr>
                <td>1</td>
                <td>Basic Mining Engineering</td>
                <td>105</td>
              </tr>
              <tr>
                <td>1</td>
                <td>Basic Mining Engineering</td>
                <td>105</td>
              </tr>
              <tr>
                <th>Name of the center</th>
                <td>center Name</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> -->