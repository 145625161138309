<app-banner banner="assets/img/siteName.png"></app-banner>

<!-- <section class="page-header" style="background: url('assets/img/siteName.png');">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
            </div>
        </div>
    </div>
</section> -->


<div class="result-form">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="page-title">
                    <h2>
                        Registration Form
                    </h2>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="form-block">
                <app-registration-form class="multi-step-form"></app-registration-form>
                <!-- Form starts -->
            </div>
        </div>
    </div>
</div>